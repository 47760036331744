import React from 'react';
import Man1 from 'images/men/bulle1.svg';
import Man2 from 'images/men/bulle2.svg';
import Man3 from 'images/men/bulle3.svg';

export const testimonies = [
  {
    testimony:
      'C’est un peu cliché mais après avoir eu les enfants et vécu plusieurs années de routine, j’ai senti que mon compagnon et moi étions en train de nous éloigner. On a décidé de prendre le problème à bras le corps dès qu’on s’en est rendu compte pour ne pas se perdre. On a bien fait, les séances nous font beaucoup de bien et on se redécouvre. C’est reparti pour un tour !',
    patient: 'Juliette',
    city: '',
    age: '36 ans',
    Icon: Man1,
    backgroundColorClass: 'lc-light-blue',
    flower: true,
    target: false,
  },
  {
    testimony:
      'Suite à mon divorce, j’ai rencontré une nouvelle personne. Nous sommes tombés amoureux et je redécouvre tout un nouvel univers, passionnant mais aussi différent. Après des années ancrées dans nos habitudes, nous avons du mal à synchroniser nos modes de vie. Nous avons travaillé là dessus avec une sexologue du site et sommes en train de construire une très jolie histoire ensemble.',
    patient: 'Joanne',
    city: '',
    age: '46 ans',
    Icon: Man3,
    backgroundColorClass: 'lc-light-yellow',
    flower: false,
    target: true,
  },
  {
    testimony:
      'L’année dernière, j’ai été infidèle et mon compagnon m’a assuré m’avoir pardonné. Pourtant, notre relation a changé depuis et le mix entre ma culpabilité et son manque de confiance pesait de plus en plus sur notre relation de couple et même nos relations sexuelles. Grâce à l’aide de la sexologue que nous avons choisie, nous réintégrons la confiance et les tensions se dénouent encore plus facilement que ce que j’aurais pu imaginer !',
    patient: 'Aminata',
    city: '',
    age: '39 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: true,
    target: false,
  },
  {
    testimony:
      'Cela faisait 2 ans que j’étais avec ma copine et nous n\'arrivions tout simplement plus à nous parler. En seulement 2 ans, comment imaginer un quelconque avenir dans ces conditions malgré le fait qu’on s’aime ? J’ai commencé les séances avec une sexologue, elle a participé à certaines d’entre elles et nous sommes en train de nous réinventer. Plus que l’espoir, le changement est là.',
    patient: 'Clara',
    city: '',
    age: '28 ans',
    Icon: Man2,
    backgroundColorClass: 'lc-light-cyan',
    flower: false,
    target: true,
  },
];

export const sections = [
  {
    title:
      '30 % des femmes ont déjà eu mal lors des rapports',
    body: (
      <div>
        <p>
          Certains signes ou attitudes peuvent suggérer qu’un couple traverse une crise. Ils doivent
          donc être pris au sérieux. Il s’agit :
        </p>
        <p className="ml-4 mt-3">
          • Du fait de dévaloriser l’autre ou d’être dévalorisée de façon récurrente ;
          <br />
          • De mépriser ou d’être méprisée ;
          <br />
          • Que le ou la partenaire soit sur la défensive et vice-versa ;
          <br />
          • Que le couple s’évite ou s’ignore ;
          <br />
          • Le fait de ne plus avoir de désir (libido) pour son ou sa partenaire ;
          <br />
          • De ne plus se projeter ensemble (ne plus avoir de projets communs)
        </p>
        <p>
          Toutes ces attitudes peuvent participer à créer un climat d’insécurité, qui ne permet
          plus la communication. Or, un couple qui ne communique plus est très souvent un couple
          qui va mal : toutes les difficultés peuvent en effet être amplifiées ou prendre
          davantage d’ampleur, s’il n’est pas possible de communiquer.
        </p>
        <p>
          Cela contribue aussi à mettre de la distance entre les partenaires ; les problèmes
          finissent par s’accumuler et devenir plus fréquents. Chacun est enfermé dans sa propre
          souffrance, n’accorde plus d’attention à l’autre, et les incompréhensions, les non-dits,
          finissent inéluctablement par éloigner le couple sur le plan émotionnel et physique.
        </p>
        <p>
          Un autre signe évocateur est le fait d’être obnubilée par les défauts de l’autre, et de
          perdre de vue ses qualités…
        </p>
        <p>
          Enfin, lorsqu’aucune intimité et que l’affection à disparue entre les partenaires, et que
          l’on commence à « compter » les erreurs, les points négatifs chez l’autre («
          <i> Tu ne fais jamais ça </i>
          », «
          <i> j’aurais aimé que tu fasses ceci </i>
          », «
          <i> il ou elle oublie tout le temps de… etc. </i>
          ») : là aussi, cela peut être le signe que le couple traverse une crise.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quels sont les problèmes de couple les plus fréquents ?',
    body: (
      <div>
        <p>
          Généralement, à l’origine d’une crise de couple, on retrouve très souvent un défaut ou un
          manque de communication.
        </p>
        <p>
          Les problèmes de couple les plus fréquents concernent :
          <p className="ml-4 mt-3">
            • Des difficultés ou une impossibilité à communiquer ;
            <br />
            • La jalousie : cela peut aussi concerner les écarts de salaire, les finances,
            la gestion de l’argent, etc.
            <br />
            • Des tâches ménagères mal réparties ;
            <br />
            • Des troubles sexuels, souvent en lien avec une mauvaise communication ;
            <br />
            • Un manque d’affection, d’attention ou d’amour ;
            <br />
            • Ou tout simplement des priorités différentes…
          </p>
        </p>
        <p>
          Avec le temps, les difficultés peuvent aussi s’accumuler : difficultés à écouter l’autre,
          se faire entendre ou communiquer, problèmes liés à l’intimité et à la vie sexuelle, au
          désir, etc.
        </p>
        <p>
          C’est pour toutes ces raisons qu’il faut apprendre à gérer les conflits et surmonter les
          crises de la bonne manière : communication, écoute et bienveillance sont les piliers du
          couple.
        </p>
      </div>
    ),
  },
  {
    title:
      'À qui parler de ses problèmes de couple ?',
    body: (
      <div>
        <p>
          Plusieurs spécialistes peuvent venir en aide au couple, selon la nature de leurs
          difficultés : la psychologue du couple, la conseillère conjugale ou encore la sexologue.
        </p>
        <p>
          Les problématiques familiales et conjugales peuvent être discutées avec la psychologue du
          couple, qui peut alors proposer une thérapie au couple ou une psychothérapie. Les
          thérapies sont axées sur la communication, des conseils pratiques, des exercices à
          réaliser entre les séances, et peuvent aussi aborder la vie sexuelle du couple.
        </p>
        <p>
          L’idée est de trouver des compromis pour mieux vivre ensemble, si un projet de couple
          continue d’habiter les deux parties ; le cas contraire, la thérapie peut permettre une
          séparation, sans rancœur,  après avoir identifié les causes de la souffrance du couple et
          les désirs de chacun.
        </p>
        <p>
          Donc, pour résumer, la thérapie de couple ne cherche pas « à tout prix » la continuité du
          couple, mais le bien-être des deux membres. Le rôle de la praticienne est donc d’adopter un
          point de vue neutre, afin de permettre au couple de communiquer, tout en reformulant les
          désirs ou les attentes de chacun si nécessaire.
        </p>
        <p>
          Pour conclure, un entretien dure généralement une demi-heure, avec pour mission de
          redonner au couple un environnement d’écoute et de parole.
        </p>
      </div>
    ),
  },
  {
    title:
      'Comment téléconsulter une sexologue sur Mia ?',
    body: (
      <div>
        <p>
          Avant la téléconsultation, il est d’abord nécessaire de remplir un questionnaire médical
          précis, qui permettra au sexologue de connaître d’éventuels antécédents médicaux. Ensuite,
          la patiente peut choisir le créneau horaire qui lui convient le mieux, ainsi que le jour
          et la date.
        </p>
        <p>
          <p>
            3 modes de téléconsultation s’offrent alors :
          </p>
          <p className="ml-4 mt-3">
            • Par
            {' '}
            <b>vidéo ;</b>
            <br />
            •
            {' '}
            <b>Messages sécurisés ;</b>
            <br />
            • Ou par
            {' '}
            <b>téléphone.</b>
          </p>
        </p>
        <p>
          Une fois le RDV confirmé et les informations personnelles remplies, le prix de la
          téléconsultation doit être préréglé sur la plateforme.
        </p>
        <p>
          Lorsque la consultation est terminée, le dossier patient est accessible à tout moment dans
          l’espace personnel, ainsi que le compte-rendu de la consultation et les recommandations
          d’accompagnement.
        </p>
      </div>
    ),
  },
  {
    title:
      'Quels sont les avantages de Mia.co ?',
    body: (
      <div>
        <p>
          • Une équipe de praticiennes (sexologues, sages-femmes, thérapeutes de couple)
          spécialistes de la sexualité féminine et spécialement formées à la pratique de la
          téléconsultation ;
        </p>
        <p>
          • Un comité scientifique composé de médecins sexologues, de professeures d’université en
          sexologie et de membres dirigeants d’associations de sexologues ;
        </p>
        <p>
          • Une consultation de 30 min à 45 euros (les prix en cabinet varient de 80 à 100 euros) ;
        </p>
        <p>
          • La livraison de produits d’accompagnement en 24 ou 48H.
        </p>
      </div>
    ),
  },
];
